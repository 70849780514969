import {
  AnimationTriggerMetadata,
  AnimationEvent,
  trigger,
  state,
  transition,
  style,
  animate,
} from '@angular/animations';
import {
  AfterViewInit,
  Component,
  ElementRef,
  HostBinding,
  Inject,
  OnDestroy,
  Optional,
  ViewChild
} from "@angular/core";
import { MatButton } from "@angular/material/button";
import { Subscription } from "rxjs";
import { NotificationMessage, ToastPanelData } from "../../../../models/notification.model";
import { MAT_SNACK_BAR_DATA, MatSnackBarConfig, MatSnackBarRef } from "@angular/material/snack-bar";

export const toastAnimations: {
  readonly showHideToast: AnimationTriggerMetadata;
} = {
  showHideToast: trigger('showHideAnimation', [
    state('in', style({ transform: 'scale(1)', opacity: 1 })),
    transition('void => opened', [style({ transform: 'scale(0)', opacity: 0 }), animate('{{ open }}ms')]),
    transition(
      'opened => closing',
      animate('{{ close }}ms', style({ transform: 'scale(0)', opacity: 0 })),
    ),
  ]),
};

export type ToastAnimationState = 'default' | 'opened' | 'closing';

@Component({
  selector: 'frmg-snack-bar-component',
  templateUrl: 'snack-bar.component.html',
  styleUrls: ['snack-bar.component.scss'],
  animations: [toastAnimations.showHideToast]
})
export class FrmgSnackBarComponent implements AfterViewInit, OnDestroy {

  @ViewChild('actionButton', {static: true}) actionButton: MatButton;

  @HostBinding('class')
  get panelClass(): string[] {
    return this.data.panelClass;
  }

  private parentEl: HTMLElement;
  private snackBarContainerEl: HTMLElement;
  private parentScrollSubscription: Subscription = null;

  public notification: NotificationMessage;

  animationState: ToastAnimationState;

  animationParams = {
    open: 100,
    close: 100
  };

  constructor(@Inject(MAT_SNACK_BAR_DATA)
              private data: ToastPanelData,
              @Optional()
              private snackBarRef: MatSnackBarRef<FrmgSnackBarComponent>) {
    this.animationState = !!this.snackBarRef ? 'default' : 'opened';
    this.notification = data.notification;
  }

  ngAfterViewInit() {
    if (this.snackBarRef) {
      this.parentEl = this.data.parent.nativeElement;
      this.snackBarContainerEl = document.querySelector('.mat-mdc-snack-bar-container');
      this.snackBarContainerEl.style.position = 'absolute';
      this.updateContainerRect();
      this.updatePosition(this.snackBarRef.containerInstance.snackBarConfig);
    }
  }

  private updatePosition(config: MatSnackBarConfig) {
    const isRtl = config.direction === 'rtl';
    const isLeft = (config.horizontalPosition === 'left' ||
      (config.horizontalPosition === 'start' && !isRtl) ||
      (config.horizontalPosition === 'end' && isRtl));
    const isRight = !isLeft && config.horizontalPosition !== 'center';
    if (isLeft) {
      this.snackBarContainerEl.style.justifyContent = 'flex-start';
    } else if (isRight) {
      this.snackBarContainerEl.style.justifyContent = 'flex-end';
    } else {
      this.snackBarContainerEl.style.justifyContent = 'center';
    }
    if (config.verticalPosition === 'top') {
      this.snackBarContainerEl.style.alignItems = 'flex-start';
    } else {
      this.snackBarContainerEl.style.alignItems = 'flex-end';
    }
  }

  private updateContainerRect() {
    const viewportOffset = this.parentEl.getBoundingClientRect();
    if (this.snackBarContainerEl.style) {
      this.snackBarContainerEl.style.top = viewportOffset.top + 'px';
      this.snackBarContainerEl.style.left = viewportOffset.left + 'px';
      this.snackBarContainerEl.style.width = viewportOffset.width + 'px';
      this.snackBarContainerEl.style.height = viewportOffset.height + 'px';
    }
  }

  ngOnDestroy() {
    if (this.parentScrollSubscription) {
      this.parentScrollSubscription.unsubscribe();
    }
  }

  action(event: MouseEvent): void {
    event.stopPropagation();
    if (this.snackBarRef) {
      this.snackBarRef.dismissWithAction();
    } else {
      this.animationState = 'closing';
    }
  }

  onHideFinished(event: AnimationEvent) {
    const { toState } = event;
    const isFadeOut = (toState as ToastAnimationState) === 'closing';
    const itFinished = this.animationState === 'closing';
    if (isFadeOut && itFinished) {
      this.data.destroyToastComponent();
    }
  }
}