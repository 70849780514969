import { Component, Input, OnInit } from '@angular/core';
import { getRiskColors } from "../../../../utils";

interface SectionInterface {
  color: string,
  value: number,
  title: string,
  percentage: string | number
}

@Component({
  selector: 'frmg-histogram-bar',
  templateUrl: './histogram-bar.component.html',
  styleUrls: ['./histogram-bar.component.scss']
})


export class HistogramBarComponent implements OnInit{
  @Input() data: number[] = [];
  public validSections: Array<SectionInterface> = [];
  public colors = getRiskColors();


  ngOnInit(): void {
    const totalValue = this.data.reduce((sum, section) => sum + section, 0);
    this.validSections =  this.sumPairs(this.data)
      .map((section, index) => ({
        color: this.colors[index],
        value: section,
        title: this.getTitle(index),
        percentage: ((section / totalValue) * 100).toFixed(0),
      })).filter(section=> +section.percentage > 0);
  }


  private getTitle(value: number): string {
    switch (value) {
      case 0:
        return  "0 - 40";
      case 1:
        return  "40 - 80";
      case 2:
        return  "80 - 120";
      case 3:
        return  "120 - 160";
      case 4:
      default:
        return  "160 - 200";
    }
  }

  private sumPairs(arr: number[]) {
    if (arr.length !== 10) {
      throw new Error("Input array must contain exactly 10 numbers.");
    }
    const result = [];
    for (let i = 0; i < arr.length; i += 2) {
      result.push(arr[i] + arr[i + 1]);
    }
    return result;
  }
}
