

<div  class="horizontal-bar-container"
      customToolTip
      [contentTemplate]="TooltipContent">
    <ng-container *ngFor="let section of validSections; let i = index">
        <div    class="bar-section"
                [style.backgroundColor]="section.color"
                [style.flex]="'0 0 calc(' + section.percentage + '% - 2px)'"
                [class.left-radius]="i === 0"
                [class.right-radius]="i === validSections.length - 1"
        ></div>
    </ng-container>
</div>

<ng-template #TooltipContent>
    <div class="custom-tooltip">
        <div class='tooltip-item' *ngFor="let section of validSections; let i = index">
            <div class='tooltip-color' [ngStyle]="{'background-color': section.color }" ></div>
            <span>{{section.title}}</span>
            <span style="flex-grow: 1"></span>
            <span>{{section.percentage}}%</span>
        </div>
    </div>
</ng-template>
