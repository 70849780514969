import { Component, Input, OnInit, TemplateRef } from '@angular/core';

@Component({
  selector: 'frmg-custom-tooltip',
  templateUrl: './custom-tooltip.component.html',
  styleUrls: ['./custom-tooltip.component.scss']
})
export class ContentTooltipComponent implements OnInit {
  @Input() contentTemplate: TemplateRef<any>;

  constructor() { }

  ngOnInit() {
  }
}