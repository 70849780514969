<div class="mat-typography">
    <div class="dialog-header">
        Are you sure you want to delete selected area{{data.areas.length !== 1? "s" : ""}}?
    </div>

    <div class="horizontal-separator"></div>

    <div class="dialog-container">
        This will permanently remove it from the list.
    </div>

</div>
<div class="button-row ">
    <button mat-button mat-dialog-close class="frmg-secondary-button">Cancel</button>
    <button mat-button class="frmg-primary-button"
            [disabled]="isLoading"
    (click)="delete()">Delete</button>
</div>
