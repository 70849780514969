import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ReportService } from "../../../services/report.service";
import { LayerService } from "../../../services/layer.service";
import { debounceTime, take } from "rxjs";
import { NotificationService } from "../../../services/notification.service";
import {
  ApexAxisChartSeries,
  ApexChart,
  ApexDataLabels,
  ApexFill,
  ApexNoData,
  ApexPlotOptions,
  ApexStates,
  ApexTitleSubtitle,
  ApexTooltip,
  ApexXAxis,
  ApexYAxis,
  ChartComponent
} from 'ng-apexcharts';
import { MatDialog, MatDialogRef } from "@angular/material/dialog";

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  fill: ApexFill;
  title: ApexTitleSubtitle;
  noData: ApexNoData;
  tooltip: ApexTooltip;
  states?: ApexStates;
};

@Component({
  selector: 'frmg-report-area-details',
  templateUrl: './report-area-details-modal.component.html',
  styleUrls: ['./report-area-details-modal.component.scss']
})
export class ReportAreaDetailsComponent implements OnInit {
  @ViewChild("chart") chart: ChartComponent;
  @ViewChild("chartFire") chartFire: ChartComponent;
  @ViewChild("chartAPX") chartAPX: ApexChart;
  @ViewChild("chartAPXExp") chartAPXExp: ApexChart;
  public chartOptions: Partial<ChartOptions>;
  public chartFireOptions: Partial<ChartOptions>;

  @Input() detailsId!: string;
  @Input() id!: string;
  @Input() collectionId!: string;

  public data: any = {};
  public exposureLayerGradient: string;
  public nearestPointIcon: string = 'assets/icons/map-pin-grey.svg';
  public exposureIcon: string = 'assets/icons/map-pin-inactive.svg';
  public highRiskIcon: string = 'assets/icons/circle-up-grey.svg';
  public lowRiskIcon: string = 'assets/icons/circle-down-green.svg';
  public distanceIcon: string = 'assets/icons/distance-icon.svg';
  public lineLength: string;
  public fullImgDialogRef: MatDialogRef<any>;

  constructor(
    private reportService: ReportService,
    private layerService: LayerService,
    private notificationService: NotificationService,
    public dialog: MatDialog
  ) {
    this.chartOptions = {
      series: [],
      chart: {
        type: "bar",
        toolbar: {
          show: false,
        },
        height: "100%",
        events: {
        },
        zoom: {
          enabled: false,
        },
        redrawOnParentResize: true
      },
      fill: {
        colors: ["#5E84F7"],
        opacity: 1,
        type: 'gradient',
        gradient: {
          gradientToColors: ["#547BF2"],
          inverseColors: true
        }
      },
      plotOptions: {
        bar: {
          borderRadius: 5,
          borderRadiusApplication: "end",
          columnWidth: '100%',
        }
      },
      dataLabels: {
        enabled: false
      },
      xaxis: {
        categories: [0, 20, 40, 60, 80, 100, 120, 140, 160, 180, 200],
        tickPlacement: "on",
        labels: {
          style: {
            fontFamily: "Montserrat, sans-serif"
          }
        },
        title: {
          text: "Exposure Value",
          offsetX: 0,
          style: {
            fontSize: "14px",
            fontWeight: "500",
            fontFamily: "Montserrat, sans-serif"
          }
        }

      },
      yaxis: {
        labels: {
          style: {
            fontFamily: "Montserrat, sans-serif"
          },
          formatter(val: number, opts?: any): string | string[] {
            if (val / 1000 > 1) {
              return (val / 1000).toFixed(0) + 'k'
            }
            return `${val}`
          }
        },
        title: {
          text: "Percentage",
          offsetX: -7,
          style: {
            fontSize: "14px",
            fontWeight: "500",
            fontFamily: "Montserrat, sans-serif"
          }
        }
      },
      noData: {
        text: 'Loading...'
      },
      tooltip: {
        enabled: false,
        x: {
          show: false
        }
      },
      states: {
        active: {
          filter: {
            type: 'none' /* none, lighten, darken */
          }
        },
        hover: {
          filter: {
            type: 'none'
          }
        }
      }
    }
    this.chartFireOptions = {
      series: [],
      chart: {
        type: "line",
        toolbar: {
          show: false,
        },
        height: "100%",
        events: {
        },
        zoom: {
          enabled: false,
        },
        redrawOnParentResize: true
      },
      plotOptions: {},
      dataLabels: {
        enabled: false
      },
      xaxis: {
        // type: 'category',
        // categories: ['1992', '2005'],
        tickAmount: 6,
        labels: {
          show: true,
          maxHeight: 60,
          hideOverlappingLabels: true,
          style: {
            fontFamily: "Montserrat, sans-serif"
          }
        },
        tooltip: {
          enabled: false
        },
        title: {
          text: "Year",
          style: {
            fontSize: "14px",
            fontWeight: "500",
            fontFamily: "Montserrat, sans-serif"
          }
        }
      },
      yaxis: {
        tickAmount: 5,
        decimalsInFloat: 0,
        labels: {
          style: {
            fontFamily: "Montserrat, sans-serif"
          }
        },
        title: {
          text: "Hectares Burned",
          offsetX: -7,
          style: {
            fontSize: "14px",
            fontWeight: "500",
            fontFamily: "Montserrat, sans-serif"
          }
        }
      },
      fill: {
        colors: ["#5E84F7"],
        opacity: 1,
        type: 'gradient',
        gradient: {
          gradientToColors: ["#547BF2"],
          inverseColors: true
        }
      },
      noData: {
        text: 'Loading...'
      },
      tooltip: {
        enabled: true,
        custom: function ({series, seriesIndex, dataPointIndex, w}) {
          const initData = w.globals.initialSeries[0].data[dataPointIndex];
          return `<div style="width: 100%; height: 100%; padding: 10px 12px;background: #F7F9F9; box-shadow: 0 4px 10px rgba(0, 0, 0, 0.18); border-radius: 4px; display: flex">
            <div style="color: #272727; font-size: 14px; font-family: Montserrat,sans-serif; font-weight: 500;">${initData.x}:</div>
            <div style="color: #5E84F7; font-size: 14px; font-family: Montserrat,sans-serif; font-weight: 600; margin-left: 16px">${initData.y}</div>
           </div>`
        },

      },
    }

  }

  ngOnInit(): void {

    this.reportService.getReportDetails(this.id, this.collectionId, this.detailsId).subscribe(resp => {
      this.chartOptions.series = [{
        name: 'histogram_data',
        data: resp.histogram_data.data.percentages.map(data=>data.toFixed(1))
      }];
      // this.chartFireOptions.xaxis.categories = resp.fire_trends_data.labels;
      if (typeof resp.fire_trends_data === 'string') {
        resp.fire_trends_data = JSON.parse(resp.fire_trends_data);
      }
      this.chartFireOptions.series = [{
        name: 'fire_data',
        data: resp.fire_trends_data.data.map((data, index) => {
          return {x: resp.fire_trends_data.labels[index], y: data}
        })
      }];
      // @ts-ignore
      this.chartAPX.updateOptions(this.chartFireOptions)
      this.data = {...this.data, ...resp};
      this.lineLength = Math.abs((this.data.from_distance - this.data.to_distance) / 1000).toFixed(0)
      this.reportService.breadCrumbs.next([...this.reportService.breadCrumbs.getValue(), resp.address.name])
    })
    this.reportService.getReport(this.id).subscribe(resp => {
      this.data = {...this.data, ...resp};
    })
    this.layerService.getLayers().pipe(debounceTime(0), take(1)).subscribe(reesp => {
      const exposureLayer = reesp.find(layer => layer.display_name.toLowerCase() === 'exposure')
      const colors = Object.entries(exposureLayer.explicit_color_map).map(([key, value]) => `${value} ${+key / 2}%`).join(', ');
      this.exposureLayerGradient = `linear-gradient(to right, ${colors})`;
    })
  }

  public downloadArea(event: Event): void {
    event.stopPropagation();
    const id = this.detailsId;
    const filename = this.data.analysis.name;
    this.reportService.getAreaReportPdf(this.data.report.id, id, filename).subscribe(data => {
      this.notificationService.updateDownload({
        fileName: filename + '.xlsx',
        percentage: data.progress,
        total: data.total,
        loaded: data.loaded
      })
    })
  }

  public openImage(templateRef) {
    this.fullImgDialogRef = this.dialog.open(templateRef, {
      // width: '75%',
      height: '75%',
    });
  }

  public colapseImage() {
    this.fullImgDialogRef?.close()
  }

  public downloadAreaXLSX(event: Event): void {
    event.stopPropagation();
    const id = this.data.analysis.id;
    const filename = this.data.analysis.name;
    this.reportService.getAreaReportXLSX(this.data.report.id, id, filename).subscribe(data => {
      this.notificationService.updateDownload({
        fileName: filename + '.xlsx',
        percentage: data.progress,
        total: data.total,
        loaded: data.loaded
      })
    })
  }

  //
  // public closeDialog(result?: any): void {
  //   this.dialogRef.close(result);
  // }

}
