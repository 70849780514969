import {
  ChangeDetectorRef,
  Component,
  computed,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  signal,
  ViewChild
} from '@angular/core';
import { FormBuilder, FormControl } from "@angular/forms";
import { Area, FilterBody, Group } from "../../../../models/general.models";
import { AreaService } from "../../../../services/area.service";
import { MatDialog } from "@angular/material/dialog";
import { DeleteAreaDialogComponent } from "../delete-area-dialog/delete-area-dialog.component";
import { debounceTime, forkJoin, take, takeWhile } from "rxjs";
import { DownloadDialogComponent } from "../download-dialog/download-dialog.component";
import { LayerService } from "../../../../services/layer.service";
import { MapCommunicatorService } from "../../map-communicator.service";
import { MatMenuTrigger } from "@angular/material/menu";
import { ToastNotificationService } from "../../../../services/toast-notification.service";
import { SaveAreaDialogComponent } from "../save-area-dialog/save-area-dialog.component";
import { RiskReportModalComponent } from "../risk-report-modal/risk-report-modal.component";
import { ChildProject, Project } from "../../../../models/project.module";
import { ProjectService } from "../../../../services/project.service";
import { CreateProjectModalComponent } from "./create-project-modal/create-project-modal.component";
import { ConfirmationDialogComponent } from "../../../../components/confirmation-dialog/confirmation-dialog.component";
import { ShareModalComponent } from "../../../../components/share-modal/share-modal.component";
import { User } from "../../../../models/user.model";
import { error } from "ol/console";

@Component({
  selector: 'frmg-area-list',
  templateUrl: './area-list.component.html',
  styleUrls: ['./area-list.component.scss'],
})
export class AreaListComponent implements OnInit, OnDestroy {


  private isMapCreated: boolean = true;

  public searchAreaCtrl: FormControl;
  public currentUser: User;
  public showSearch: boolean = false;
  public showSearchResults: boolean = false;
  public list: Array<Area & { selected?: boolean, highlighted?: boolean }> = [];
  public editingArea?: Area & { selected?: boolean, highlighted?: boolean };
  public editingProject?: ChildProject;
  public newName: string;
  public projects: ChildProject[] = [];
  public currentProject: Project;
  public archivedToggle: boolean = false;
  public searchType: string = 'all';
  public searchResults: Array<any> = [];

  public areasLoading = signal(false);
  public projectsLoading = signal(false);
  public loadingData = computed(() => this.areasLoading() || this.projectsLoading())


  @ViewChild('listContainer') listContainer: ElementRef<HTMLElement>;
  @ViewChild(MatMenuTrigger) menuTrigger: MatMenuTrigger;
  @ViewChild(MatMenuTrigger) filterTrigger: MatMenuTrigger;
  @ViewChild('searchInput', {static: false, read: ElementRef}) searchInput: ElementRef;

  @Output() projectSelect = new EventEmitter<Project | null>();
  @Output() onDestroyCallback = new EventEmitter<any | null>();

  @Input() initState: {
    list: Array<Area & { selected?: boolean, highlighted?: boolean }>,
    projects: ChildProject[],
    currentProject: Project,
    archivedToggle: boolean
  };

  constructor(
    private fb: FormBuilder,
    public areaService: AreaService,
    private projectService: ProjectService,
    private mapCommunicatorService: MapCommunicatorService,
    private dialog: MatDialog,
    private cd: ChangeDetectorRef,
    private layerService: LayerService,
    private toastNotificationService: ToastNotificationService
  ) {
    this.searchAreaCtrl = this.fb.control('');
    this.currentUser = JSON.parse(localStorage.getItem('user')) as User;
  }

  ngOnInit(): void {
    if (this.initState) {
      this.list = this.initState.list;
      this.currentProject = this.initState.currentProject;
      this.projects = this.initState.projects;
      this.archivedToggle = this.initState.archivedToggle;
    } else {
      this.loadProjects();
    }
    this.searchAreaCtrl.valueChanges.pipe(
      debounceTime(500)).subscribe(query => {
      this.showSearch && this.startSearch(query);
    })


    this.mapCommunicatorService.mapCommunicator.pipe(takeWhile(() => this.isMapCreated)).subscribe(command => {
      if (command.startsWith('map_select_')) {
        const id = command.split('map_select_')[1];
        Array.from(this.listContainer.nativeElement.children).find(ch => ch.id === id)?.scrollIntoView()
        const selectedArea = this.list.findIndex(area => area.properties.id === id);
        if (selectedArea > -1) {
          if (!this.list[selectedArea]!.selected) {
            this.list[selectedArea]!.selected = false;
          }
          this.list[selectedArea].selected = !this.list[selectedArea].selected;
        }
        this.cd.detectChanges();
      } else if (command == 'refresh_list') {
        this.updateProjectData(this.currentProject);
      } else if (command == 'added_new_area') {
        this.areaService.areasList.pipe(debounceTime(0), take(1)).subscribe(currentList => {
          this.list.push(currentList[currentList.length - 1]);
        })

      }
    })
  }

  public startSearch(query?: string) {
    if (query.length) {
      forkJoin(
        [this.areaService.searchAreas(query, this.searchType == 'all' ? undefined : this.currentProject.id),
          this.projectService.searchProjects(query)])
        .subscribe(results => {
          this.searchResults = [...results[0], ...results[1]];
          this.cd.detectChanges();
        })
    } else {
      this.searchResults = [];
      this.cd.detectChanges();
    }

  }

  ngOnDestroy() {
    this.isMapCreated = false;
    this.onDestroyCallback.emit({
      list: this.list,
      projects: this.projects,
      currentProject: this.currentProject,
      archivedToggle: this.archivedToggle
    })
  }

  public getSearchResultsByType(type: string) {
    switch (type) {
      case 'projects':
        return this.searchResults.filter(item => item.name && item.is_project);
      case 'folders':
        return this.searchResults.filter(item => item.name && !item.is_project);
      case 'aois':
        return this.searchResults.filter(item => !item.name);
      default:
        return this.searchResults
    }
  }

  public moreUsers(project: ChildProject): User {
    if (project.shared_with.length == 3) {
      return project.shared_with[2];
    } else {
      return {
        id: 0,
        teams: [],
        email: `+${project.shared_with.length - 2}`
      }
    }
  }

  public folderType(project: ChildProject): string {
    if (this.currentProject.path === 'root') {
      return 'project'
    } else return project.is_archived ? 'archived' : 'folder';
  }

  public loadProjects() {
    this.areaService.areasList.next([]);
    this.projectsLoading.set(true)
    this.cd.detectChanges();
    this.projectService.getProjectsByPath('root', this.archivedToggle).subscribe(resp => {
      this.projects = resp.children;
      this.currentProject = resp;
      this.projectSelect.emit(resp);
      this.projectsLoading.set(false)
      this.cd.detectChanges();
    })
  }

  getAreaIconType(area: Area): string {
    switch (area.geometry.type) {
      case "Circle":
      case "Polygon":
      case "MultiPolygon":
        return 'icon-area-poly';
      case "LineString":
      case "MultiLineString":
      case "LinearRing":
        return 'icon-area-line';
      case "Point":
      case "MultiPoint":
      default:
        return 'icon-area-point';

    }
  }

  public getProjects() {
    return this.projects.filter(project => project.is_archived == this.archivedToggle)
  }

  private clearSearch() {
    this.searchAreaCtrl.setValue('');
    this.searchResults = [];
  }

  public toggleSearch() {
    this.showSearch = !this.showSearch;
    if (!this.showSearch) {
      this.clearSearch();
    } else {
      setTimeout(() => {
        this.searchInput.nativeElement.focus();
      })
    }
  }

  public createGroupString(groups: Group[]) {
    return groups.map(group => '#' + group.name).join(' ')
  }

  public deleteAreas(areas: string[]) {
    if (!areas.length) return;
    const dialog = this.dialog.open(DeleteAreaDialogComponent, {
      data: {
        areas
      }
    })
    dialog.afterClosed().subscribe(result => {
      if (!result) return;
      areas.forEach(area => {
        const index = this.list.findIndex(listA => listA.properties.id === area);
        if (index !== -1) this.list.splice(index, 1);
      })
      this.areaService.getAreas('', true, this.currentProject.id).subscribe(areas => {
        this.list = areas;
        this.cd.detectChanges();
      })
    })
  }

  public areaChecked(area: Area & { selected?: boolean }, event: Event) {
    if (event) event.stopPropagation();
    if (this.editingArea || !event) return;
    if (!area.selected) area.selected = false;
    area.selected = !area.selected;
    this.mapCommunicatorService.selectedAreas.next(this.list.filter(a => a.selected).map(a => a.properties.id));
  }

  public someChecked(): boolean {
    return this.list.length && !!this.list.filter(a => a.selected).length && !this.allChecked();
  }

  public setAll(all: boolean): void {
    this.list.forEach(a => (a.selected = all));
    this.mapCommunicatorService.selectedAreas.next(this.list.filter(a => a.selected).map(a => a.properties.id));
  }

  public allChecked(): boolean {
    return this.list.length && this.list.every(a => a.selected);
  }

  public getCheckedIds(): string[] {
    return this.list.filter(a => a.selected).map(a => a.properties.id);
  }

  public focusOn(area: Area, event: Event) {
    event.stopPropagation();
    this.mapCommunicatorService.mapCommunicator.next('focus_' + area.properties.id);
  }

  public downloadSelected() {
    if (!this.getCheckedIds().length) return;
    this.layerService.getLayers().subscribe(layers => {
      this.dialog.open(DownloadDialogComponent, {
        width: '1100px !important',
        data: {
          layerList: layers,
          areaList: this.list.filter(a => a.selected)
        }
      })
    })
  }

  public changeName(edit: Area, event: Event): void {
    event.stopPropagation();
    this.editingArea = edit;
    this.newName = this.editingArea.properties.name
  }

  public changeNameProject(edit: ChildProject, event: Event): void {
    event.stopPropagation();
    this.editingProject = edit;
    this.newName = this.editingProject.name;
  }

  public saveName(event: Event): void {
    event.stopPropagation();
    this.areaService.changeArea(this.editingArea as Area, {name: this.newName}).subscribe(resp => {
      delete this.editingArea;
      this.list.find(area => area.properties.id === resp.properties.id)!.properties.name = this.newName;
      this.cd.detectChanges();
    }, error => {
      this.toastNotificationService.showNotification({message: error.error.properties.name[0], type: "error"})
    });
  }

  public saveNameProject(event: Event): void {
    event.stopPropagation();
    this.projectService.changeProject(this.editingProject.id, {name: this.newName}).subscribe(resp => {
      delete this.editingProject;
      const editedProject = this.projects.find(project => project.id === resp.id);
      editedProject.name = resp.name;
      editedProject.path = resp.path;
      this.cd.detectChanges();
    }, error => {
      this.toastNotificationService.showNotification({message: error.error.name[0], type: "error"})
    });
  }

  public cancelName(event: Event): void {
    event.stopPropagation();
    delete this.editingArea;
  }

  public cancelNameProject(event: Event): void {
    event.stopPropagation();
    delete this.editingProject;
  }

  public editArea(area: Area, event: Event): void {
    event.stopPropagation();
    this.dialog.open(SaveAreaDialogComponent, {
      data: {
        layer: area,
        project: this.currentProject
      },
    })
  }

  public openProject(project: ChildProject, event: Event): void {
    if (this.editingProject && project.id === this.editingProject.id) return;
    if (project.is_archived) return;
    event.stopPropagation();
    this.projectsLoading.set(true);
    this.cd.detectChanges();
    this.projectService.getProjectsByPath(project.path).subscribe(resp => {
      this.updateProjectData(resp)
    }, error =>{
      this.loadProjects();
    });
  }

  public goProjectUp(event: Event) {
    event.stopPropagation();
    this.list = [];
    this.projects = [];
    this.mapCommunicatorService.selectedAreas.next([]);
    this.projectsLoading.set(true);
    this.cd.detectChanges();
    this.projectService.getProjectsByPath(this.getParentPath(this.currentProject)).subscribe(resp => {
      this.updateProjectData(resp)
    }, error =>{
      this.loadProjects();
    })
  }

  public getParentPath(project: Project): string {
    let pathArray = project.path.split('/');
    return pathArray.filter(path => {
      if (path == project.name) return false;
      return !project.skipped_paths.includes(path);
    }).join('/')
  }

  private updateProjectData(project: Project, afterUpdate?: Function) {
    this.currentProject = project;
    this.projectSelect.emit(project);
    if (project.is_root) {
      this.projects = project.children || []
      this.areaService.areasList.next([]);
      this.projectsLoading.set(false);
      this.cd.detectChanges();
    } else {
      this.areasLoading.set(true);
      this.projects = project.children || [];
      this.cd.detectChanges();
      this.areaService.getAreas('', true, project.id).subscribe(areas => {
        this.list = areas;
        this.areasLoading.set(false);
        this.projectsLoading.set(false);
        this.cd.detectChanges();
        if (afterUpdate) setTimeout(_ => afterUpdate(), 500);
      }, error =>{
        this.loadProjects();
      })
    }
  }

  public deleteProject(id: string, event: Event, is_project?: boolean): void {
    event.stopPropagation();
    this.dialog.open<ConfirmationDialogComponent>(ConfirmationDialogComponent, {
      data: {
        header: `Are you sure you want to delete this ${is_project ? 'project' : 'folder'}?`,
        body: `Permanently deleting this ${is_project ? 'project' : 'folder'} will prevent you from viewing or restoring it.`,
        confirmText: 'Delete',
      }
    }).afterClosed().subscribe(del => {
      if (del) {
        this.projectService.deleteProject(id).subscribe(() => {
          this.projects.forEach(() => {
            const index = this.projects.findIndex(project => project.id === id);
            if (index !== -1) this.projects.splice(index, 1);
          })
          this.cd.detectChanges();
        })
      }
    })
  }

  public archiveProject(project: ChildProject, event: Event): void {
    event.stopPropagation();
    this.dialog.open<ConfirmationDialogComponent>(ConfirmationDialogComponent, {
      data: {
        header: `Are you sure you want to ${!project.is_archived ? 'archive' : 'unarchive'} this project?`,
        body: !project.is_archived ? 'Archived projects can be restored anytime.' : 'Once unarchived, you will find it in "My Projects" with all files intact.',
        confirmText: !project.is_archived ? 'Archive' : 'Unarchive'
      }
    }).afterClosed().subscribe(del => {
      if (del) {
        this.projectService.archiveProject(project, this.currentProject.id).subscribe(() => {
          const index = this.projects.findIndex(prj => project.id === prj.id);
          if (index !== -1) {
            this.projects[index].is_archived = !this.projects[index].is_archived;
            this.cd.detectChanges();
            this.toastNotificationService.showNotification({
              type: "success",
              message: "Project was successfully " + (this.projects[index].is_archived ? 'archived.' : 'unarchived.'),
              verticalPosition: "top"
            })
          }
        })
      }

    })
  }

  public generateReport(): void {
    this.dialog.open(RiskReportModalComponent, {
      data: this.list.filter(a => a.selected)
    })
  }

  public createProject(): void {
    const createProject = this.dialog.open(CreateProjectModalComponent, {
      data: {
        parent: this.currentProject
      }
    });
    createProject.afterClosed().subscribe(resp => {
      if (resp) this.projects.push(resp)

      this.cd.detectChanges();
    })
  }

  public editProject(project: ChildProject): void {
    const createProject = this.dialog.open(CreateProjectModalComponent, {
      data: {
        parent: this.currentProject,
        project: project
      }
    });
    createProject.afterClosed().subscribe(resp => {
      if (resp) {
        const index = this.projects.findIndex(project => project.id === resp.id);
        this.projects[index] = resp;
      }

      this.cd.detectChanges();
    })
  }


  public onApplyFilter(filter: FilterBody) {
    this.filterTrigger.closeMenu();
    this.areaService.filterBody = filter;
    this.areasLoading.set(true);
    this.cd.detectChanges();
    this.areaService.getAreas('', true, this.currentProject.id).subscribe(areas => {
      this.list = areas;
      this.areasLoading.set(false);
      this.cd.detectChanges();
    })
  }

  public transformSearchPath(path: string) {
    if (!path) return `<span class="final"> Unknown destination</span>`
    let pathArray = path.split('/').map((el, index, array) => {
      if (array.length - 1 === index) {
        el = `<span class="final"> ${this.currentProject.name === el ? 'Current project' : el} </span>`
      }
      return `<span> ${el} </span>`;
    })

    pathArray.shift();

    return pathArray.join(` <span class="icon-Pagination-Arrow-1" style="transform: scaleX(-1)"></span> `)
  }

  public goToItem(item: any) {
    this.searchResults = [];
    this.showSearch = false;
    if (item.properties?.project?.path) {
      if (this.currentProject.path === item.properties?.project?.path) {
        const target = this.list.find(area => area.properties.id === item.properties.id);
        if (target) {
          target.highlighted = true;
          Array.from(this.listContainer.nativeElement.children).find(ch => ch.id === target.properties.id)?.scrollIntoView()
          setTimeout(_ => target.highlighted = false, 2000);
          this.mapCommunicatorService.mapCommunicator.next('focus_' + target.properties.id);
          this.cd.detectChanges()
        }
      } else {
        this.projectsLoading.set(true);
        this.cd.detectChanges();
        this.projectService.getProjectsByPath(item.properties.project.path).subscribe(resp => {
          this.list = [];
          this.updateProjectData(resp, () => {
            const target = this.list.find(area => area.properties.id === item.properties.id);
            if (target) {
              target.highlighted = true;
              Array.from(this.listContainer.nativeElement.children).find(ch => ch.id === target.properties.id)?.scrollIntoView()
              setTimeout(_ => target.highlighted = false, 2000);
              this.mapCommunicatorService.mapCommunicator.next('focus_' + target.properties.id);
              this.cd.detectChanges()
            }
          });
        }, error => {
          this.projectsLoading.set(false);
          this.toastNotificationService.showNotification({
            type: "error",
            message: "This area stored in archive project"
          })
        });
      }
    } else {
      this.list = [];
      this.projectsLoading.set(true);
      this.cd.detectChanges();
      this.projectService.getProjectById(item.parent).subscribe(resp => {
        this.projectService.getProjectsByPath(`${resp.path}/${item.name}`).subscribe(resp => {
          this.updateProjectData(resp);
        }, error =>{
          this.loadProjects();
        });
      }, error =>{
        this.loadProjects();
      })
    }
    this.searchAreaCtrl.setValue('');
  }

  public share(area: ChildProject): void {
    this.dialog.open(ShareModalComponent<ChildProject>, {
      data: {
        entity: area
      }
    }).afterClosed().subscribe(() => {
      this.projectService.getProjectsByPath(this.currentProject.path).subscribe(resp => {
        this.updateProjectData(resp)
      });
    })
  }

  public showResults(state: boolean) {
    setTimeout(() => {
      this.showSearchResults = state;
      this.cd.detectChanges();
    }, 500)
  }

  protected readonly parent = parent;
}
