<div class="upper-menu">
    <img class="logo" src="assets/images/logo_xs.svg" alt="skyforest" (click)="navigate('risk-map')">
    <div class="horizontal-separator"></div>

    <div class="menu-item" (click)="navigate('risk-map')"
         [ngClass]="{'active': router.url.includes('risk-map')}"
    matTooltip="Home Page" matTooltipPosition="right">
        <span class="icon-Map"></span>
    </div>
    <div class="menu-item" (click)="navigate('report-table')"
         [ngClass]="{'active': router.url.includes('report')}"
         matTooltip="Exposure Reports" matTooltipPosition="right">
        <span class="icon-file"></span>
    </div>
    <div class="menu-item" (click)="navigate('downloads')"
         [ngClass]="{'active': router.url.includes('downloads')}"
         matTooltip="My Downloads" matTooltipPosition="right">
        <span class="icon-download"></span>
    </div>
</div>
<div class="lower-menu">
    <div class="menu-item" (click)="openNotifications()"
         [ngClass]="{'active': showNotification}"
         matTooltip="Notifications" matTooltipPosition="right">
        <span class="icon-notifications" ></span>
    </div>
    <div class="horizontal-separator"></div>
    <div class="menu-item" (click)="navigate('settings')"
         [ngClass]="{'active': router.url.includes('settings')}"
         matTooltip="Settings" matTooltipPosition="right">
        <span class="icon-Settings"></span>
    </div>
    <div class="horizontal-separator"></div>
    <div class="menu-item" (click)="logout()"
         matTooltip="Log out" matTooltipPosition="right">
        <span class="icon-log-out"></span>
    </div>
</div>