import { Injectable, Inject } from '@angular/core'
import { HttpClient, HttpEventType, HttpHeaders, HttpResponse } from '@angular/common/http'
import { Observable, of, throwError, timer } from 'rxjs'
import { Saver, SAVER } from "./providers/saver.provider";
import { Download } from "../models/general.models";
import { download } from '../utils';
import { UserService } from "./user.service";
import { catchError, filter, switchMap } from "rxjs/operators";
import { ToastNotificationService } from "./toast-notification.service";

@Injectable({providedIn: 'root'})
export class DownloadService {


  constructor(
    private http: HttpClient,
    @Inject(SAVER) private save: Saver,
    private userService: UserService,
    private toastNotificationService: ToastNotificationService
  ) {
  }



  download(url: string, filename?: string): Observable<Download> {
    return this.http.get(url, {
      reportProgress: true,
      observe: 'events',
      responseType: 'blob',
      headers: new HttpHeaders({
        'Authorization': `Bearer ${this.userService.currentAccessToken}`
      })
    }).pipe(download(blob => this.save(blob, filename)))
  }

  downloadZip(url: string, filename?: string): Observable<any> {
    return this.makeRequest(url, filename);
  }

  private makeRequest(url: string, filename?: string): Observable<any> {
    return this.http.get(url, {
      reportProgress: true,
      observe: 'events',
      responseType: 'blob', // Expect a Blob, but we handle JSON responses manually
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.userService.currentAccessToken}`,
      }),
    }).pipe(
      filter(event => event.type === HttpEventType.Response), // Only process the final response
      // @ts-ignore
      switchMap(event => {
        const httpResponse = event as HttpResponse<Blob>;
        const contentType = httpResponse.headers.get('Content-Type');
        if (contentType?.includes('application/json')) {
          // Handle JSON response
          return timer(3000).pipe(switchMap(() => this.makeRequest(url, filename)));
        } else if (httpResponse.body instanceof Blob) {
          // Handle Blob response (download the file)
          this.save(httpResponse.body, filename);
          return of({ status: 'blob', progress: 100 });
        } else {
          throw new Error('Unknown response type');
        }
      }),
      catchError(error => {
        console.error('Download failed:', error);
        this.toastNotificationService.showNotification({type: "error", message: `Error downloading ${filename} file`});
        return of({ status: 'blob', progress: 100 });
      })
    );
  }


  blob(url: string, filename?: string): Observable<Blob> {
    return this.http.get(url, {
      responseType: 'blob',
      headers: new HttpHeaders({
        'Authorization': `Bearer ${this.userService.currentAccessToken}`
      })
    })
  }
}