<div class="mat-typography analysis">
    <div class="analysis-image info-block" >
        <div class="image-container">
            <img [src]="this.data.image" alt="analysis-map">
            <div class="icon-zoom-in open-full" (click)="openImage(fullImage)"></div>
        </div>
        <span class="input-label">Feature Exposure Analysis</span>
        <div class="horizontal-separator"></div>
        <div class="info-container">
            <ng-template [ngTemplateOutlet]="summaryCard"
                         [ngTemplateOutletContext]="{
                                     value: this.data.input_point_value?.toFixed(0),
                                             title: 'Exposure Value of Feature',
                                             icon: exposureIcon,
                                             shadow: false
                                     }">
            </ng-template>
            <ng-template [ngTemplateOutlet]="summaryCard"
                         *ngIf="data.distance > 10"
                         [ngTemplateOutletContext]="{
                                     value: this.data.nearest_value_actual?.toFixed(0),
                                             title: 'Exposure Value of  Nearest High-Risk Point',
                                             icon: nearestPointIcon,
                                             shadow: false
                                     }">
            </ng-template>
        </div>
        <div class="info-container">
            <ng-template [ngTemplateOutlet]="summaryCard"
                         [ngTemplateOutletContext]="{
                                     value: this.data.highest_value_actual?.toFixed(0),
                                             title: 'Highest Local Exposure Value',
                                             icon: highRiskIcon,
                                             shadow: false
                                     }">
            </ng-template>
            <ng-template [ngTemplateOutlet]="summaryCard"
                         [ngTemplateOutletContext]="{
                                     value: this.data.lowest_value_actual?.toFixed(0),
                                             title: 'Lowest Local Exposure Value',
                                             icon: lowRiskIcon,
                                             shadow: false
                                     }">
            </ng-template>
        </div>
        <div>
            <ng-template [ngTemplateOutlet]="summaryCard"
                         *ngIf="data.distance > 10"
                         [ngTemplateOutletContext]="{
                                     value: (this.data.distance?.toFixed(0)||0) +'m',
                                             title: 'Distance to Highest Risk Point',
                                             icon: distanceIcon,
                                             shadow: false
                                     }">
            </ng-template>
        </div>
    </div>
    <div class="analysis-container">
        <div class="data-block">
            <div class="info-block">
                <div>
                    <ng-template [ngTemplateOutlet]="summaryCard"
                                 [ngTemplateOutletContext]="{
                                     value: this.data.lowest_threshold?.toFixed(0),
                                             title: 'Lower Threshold',
                                             shadow: true
                                     }">
                    </ng-template>
                    <ng-template [ngTemplateOutlet]="summaryCard"
                                 [ngTemplateOutletContext]="{
                                     value: this.data.highest_threshold?.toFixed(0),
                                             title: 'Upper Threshold',
                                             shadow: true
                                     }">
                    </ng-template>
                    <ng-template [ngTemplateOutlet]="summaryCard"
                                 [ngTemplateOutletContext]="{
                                     value: this.data.threshold_percentage?.toFixed(0) +'%',
                                             title: 'Threshold percentage',
                                             shadow: true
                                     }">
                    </ng-template>
                    <ng-template *ngIf="lineLength != '0'"
                                 [ngTemplateOutlet]="summaryCard"
                                 [ngTemplateOutletContext]="{
                                     value: lineLength +'km',
                                             title: 'Segment length',
                                             shadow: true
                                     }">
                    </ng-template>
                </div>
            </div>
            <div id="chart" class="info-block">
                <div class="chart-title">Exposure Value</div>
                <apx-chart
                        #chartAPXExp
                        [series]="chartOptions.series"
                        [chart]="chartOptions.chart"
                        [dataLabels]="chartOptions.dataLabels"
                        [plotOptions]="chartOptions.plotOptions"
                        [yaxis]="chartOptions.yaxis"
                        [xaxis]="chartOptions.xaxis"
                        [fill]="chartOptions.fill"
                        [title]="chartOptions.title"
                        [noData]="chartOptions.noData"
                        [tooltip]="chartOptions.tooltip"
                        [states]="chartOptions.states"
                ></apx-chart>
            </div>
            <div id="chartFire" class="info-block">
                <div class="chart-title">Fire History (Last 50 years)</div>
                <apx-chart #chartAPX
                        [series]="chartFireOptions.series"
                        [chart]="chartFireOptions.chart"
                        [dataLabels]="chartFireOptions.dataLabels"
                        [plotOptions]="chartFireOptions.plotOptions"
                        [yaxis]="chartFireOptions.yaxis"
                        [xaxis]="chartFireOptions.xaxis"
                        [fill]="chartFireOptions.fill"
                        [title]="chartFireOptions.title"
                        [tooltip]="chartFireOptions.tooltip"
                ></apx-chart>
            </div>
        </div>
    </div>
</div>

<ng-template #summaryCard let-value="value" let-title="title" let-icon="icon" let-shadow='shadow'>
    <div [ngClass]="shadow ? 'summary-card shadowed': 'summary-card'">
        <div class="card-info">
            <p class="card-reading" [innerHTML]="value"></p>
            <p class="card-title">{{ title | translate }}</p>
        </div>
        <div class="card-icon" *ngIf="icon">
            <img [src]="icon" alt="icon"/>
        </div>
    </div>
</ng-template>

<ng-template #fullImage>
        <img [src]="this.data.image" alt="analysis-map" class="analysis-map">
        <div class="icon-close close-full" (click)="colapseImage()"></div>
</ng-template>

