<div class="report-info">

    <div class="report-summary">
        <ng-template [ngTemplateOutlet]="summaryCard"
                     [ngTemplateOutletContext]="{
        value: sizeTotal,
        title: 'Total calculated area'
        }"></ng-template>
        <ng-template [ngTemplateOutlet]="summaryCard"
                     [ngTemplateOutletContext]="{
        value: avgScore,
        title: 'Average Risk Score'
        }"></ng-template>
        <ng-template [ngTemplateOutlet]="summaryCard"
                     [ngTemplateOutletContext]="{
        value: (this.report?.total_length || '-') + ' km',
        title: 'Line Length'
        }" *ngIf="!isPolygon"></ng-template>
<!--        <ng-template [ngTemplateOutlet]="summaryCard"-->
<!--                     [ngTemplateOutletContext]="{-->
<!--        value: percentile,-->
<!--        title: 'Threshold Percentile'-->
<!--        }"></ng-template>-->
<!--        <ng-template [ngTemplateOutlet]="summaryCard"-->
<!--                     [ngTemplateOutletContext]="{-->
<!--        value: (totalElements -1) || 1,-->
<!--        title: 'Total number of segments'-->
<!--        }"></ng-template>-->
        <ng-template [ngTemplateOutlet]="summaryCard"
                     [ngTemplateOutletContext]="{
        value: threshold,
        title: 'Threshold Range'
        }"></ng-template>
    </div>

    <div class="map" [ngClass]="{'spinner' : loadingData() && !report}">
        <frmg-map [reportData]="reportDataFull" *ngIf="reportDataFull.length" (elementClick)="openAnalysisModalById($event)" [skippClickOnNotPoints]="true"/>
    </div>
    <div id="chart" class="report-chart">
        <div class="chart-title">Risk Score</div>
        <div class="chart-container">
            <apx-chart
                    [series]="chartOptions.series"
                    [chart]="chartOptions.chart"
                    [labels]="chartOptions.labels"
                    [plotOptions]="chartOptions.options"
                    [legend]="chartOptions.legend"
                    [colors]="chartOptions.colors"
                    [tooltip]="chartOptions.tooltip"
                    [stroke]="chartOptions.stroke"
            ></apx-chart>
            <div class="chart-legend">
                <div *ngFor="let legend of legends; index as i">
                    <div class="color-block" [style.background-color]="colors[i]"
                         [matTooltipPosition]="'above'" [matTooltip]="legend*40+ '-' + (legend+1)*40 "
                         matTooltipClass="exposure-color-tooltip"></div>
                    <div class="legend-block">
                        {{ legend }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="download-table" [ngClass]="{'spinner' : loadingData()}">
    <div class="table-header">
        <span>Fire Risk</span>
        <div class="header-control">
            <mat-form-field appearance="outline">
                <div class="icon-search-icon" matPrefix></div>
                <input matInput [formControl]="areaSearchCtrl" placeholder="Search by areas">
            </mat-form-field>
<!--            <span class="icon-column_selector"-->
<!--                  (click)="toggleColumnMenu()"-->
<!--                  [ngClass]="{'menu-open': columnMenu}"></span>-->
            <div *ngIf="columnMenu" class="column-menu-panel">
                <div class="column-header">
                    <div>
                        <span class="column-header-title">Configure columns</span>
                        <span class="icon-close" (click)="toggleColumnMenu()"></span>
                    </div>
                    <div class="horizontal-separator"></div>
                </div>
                <div class="columns-body" (click)="$event.stopPropagation()" cdkDropListGroup>
                    <div class="columns-shown"
                         cdkDropList
                         [cdkDropListData]="displayedColumnsDrag"
                         (cdkDropListDropped)="drop($event)">
                        <span class="list-title">
                            Shown attributes
                        </span>
                        <ng-container *ngFor="let column of displayedColumnsDrag; let i = index">
                            <div class="list-column" cdkDrag
                                 cdkDragPreviewClass="list-column">
                                <span class="icon-Drag"></span>
                                <span class="cdkDrag">{{ column }} </span>
                                <mat-slide-toggle
                                        [checked]="true"
                                        [disabled]="i == 1 || i == 2"
                                        (change)="toggleColumn(i, column, true)">
                                </mat-slide-toggle>
                            </div>
                        </ng-container>
                    </div>
                    <div class="columns-hidden">
                        <span class="list-title">
                            Hidden attributes
                        </span>
                        <div class="list-column" *ngFor="let column of hiddenColumns; let i = index">
                            <span class="icon-Drag"></span>
                            <span class="cdkDrag">{{ column }} </span>
                            <mat-slide-toggle
                                    [checked]="false"
                                    (change)="toggleColumn(i, column, false)">
                            </mat-slide-toggle>
                        </div>
                    </div>
                </div>
                <div class="column-controls">

                </div>
            </div>
        </div>
    </div>
    <div class="table-container">
        <table mat-table [dataSource]="reportData" class="mat-elevation-z8" matSort (matSortChange)="sortData($event)"
               [matSortDisabled]="loadingData()" matSortDisableClear>
            <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef>Segment Name</mat-header-cell>
                <mat-cell *matCellDef="let element"
                          [matTooltip]="element.name">
                    <div class="area-name-cell">
<!--                        <span>-->
<!--                            <div [ngClass]="getAreaIconType(element.area)"></div>-->
<!--                            <div>{{ truncate(element.area.properties.address?.name || element.area.properties.address?.display_name, 50) }}</div>-->
<!--                        </span>-->
                        <span> {{segmentLength(element)}}  </span>
                    </div>

                    <span class="icon-focus-in" (click)="focusOn(element, $event)"></span>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="first_risk_score">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Risk Score</mat-header-cell>
                <mat-cell *matCellDef="let element">{{ element.area.properties.risk_score }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="risk_percentage">
                <mat-header-cell *matHeaderCellDef>Risk percentage</mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <frmg-histogram-bar [data]="element.area.properties.histogram_data.data.raw_counts"></frmg-histogram-bar>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="avg_input_point_value">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Avg. Exposure</mat-header-cell>
                <mat-cell *matCellDef="let element">{{ element.area.properties.avg_input_point_value }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="percentile">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Total Annual Burn Frequency (%)</mat-header-cell>
                <mat-cell *matCellDef="let element">{{element.area.properties.percentile_category}} ({{ element.area.properties.percentile.toFixed(0) }}%)</mat-cell>
            </ng-container>
            <ng-container matColumnDef="nearest_value_actual">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Nearest Exposure (value)</mat-header-cell>
                <mat-cell *matCellDef="let element">{{ element.area.properties.nearest_value_actual }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="lowest_value_actual">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Exposure Lowest</mat-header-cell>
                <mat-cell *matCellDef="let element">{{ element.area.properties.lowest_value_actual }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="highest_value_actual">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Exposure Highest</mat-header-cell>
                <mat-cell *matCellDef="let element">{{ element.area.properties.highest_value_actual }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="distance">
                <mat-header-cell *matHeaderCellDef>Distance to risk</mat-header-cell>
                <mat-cell *matCellDef="let element">{{ element.area.properties.distance || 0 | round }}m</mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"
                     [style.border-color]="colors[row.area.properties.risk_score]"
                     (click)="openAnalysisModal(row)"
                     (mouseenter)="highlightOnHover(row)"></mat-row>
        </table>
    </div>
    <!--    <mat-divider></mat-divider>-->
    <!--    <div class="table-footer">Showing {{totalElements}} out of {{totalElements}} </div>-->

</div>

<ng-template #summaryCard let-value="value" let-title="title" >
    <div class="summary-card">
        <div class="card-info">
            <p class="card-reading" [innerHTML]="value"></p>
            <p class="card-title">{{ title | translate }}</p>
        </div>
    </div>
</ng-template>
