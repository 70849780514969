<div class="mat-typography" [formGroup]="saveRiskReportGroup" [ngStyle]="{width: hasPoints? '600px': '650px'}">
    <div class="dialog-header">
        Generate Exposure Report
    </div>

    <div class="horizontal-separator"></div>

    <div class="dialog-container">
        <ng-container >
<!--            <frmg-info-block type="warning" [title]="warningTitle" [body]="warningBody"/>-->


            <p class="section-title" style="margin-top: 10px">General Settings</p>
            <div class="card-block">
                <p class="input-label" style="margin-top: 0">
                    Report name
                </p>
                <mat-form-field appearance="outline">
                    <input matInput formControlName="name" placeholder="Report name" required>
                    <mat-error *ngIf="saveRiskReportGroup.get('name')?.hasError('required')">
                        Name is required
                    </mat-error>
                </mat-form-field>
                <p class="input-label">
                    Exposure Threshold <span class="icon-info" [matTooltip]="riskTooltip"
                                              matTooltipPosition="left"></span>
                </p>
                <p class="input-hint">Only areas within the selected threshold will be included in the report.</p>
                <div class="slider-container ">
                    <mat-slider
                            class="threshold-slider  risk-slider"
                            max="200"
                            min="0"
                            step="1">
                        <div class="threshold-slider-range">
                            <span>0</span>
                            <span>200</span>
                        </div>
                        <input matSliderStartThumb formControlName="threshold"
                               [value]="saveRiskReportGroup.value.threshold">
                        <input matSliderEndThumb value="200">
                    </mat-slider>
                    <div class="input-container">
                        <mat-form-field appearance="outline" class="threshold-input">
                            <input matInput formControlName="threshold" placeholder="0"
                                   [value]="saveRiskReportGroup.value.threshold">
                        </mat-form-field>
                        <span>-</span>
                        <mat-form-field appearance="outline" class="threshold-input">
                            <input matInput placeholder="200" [disabled]="true"
                                   value="200">
                        </mat-form-field>
                    </div>
                </div>
            </div>

        </ng-container>
<!--        <ng-container *ngIf="!hasPoints">-->
<!--            <frmg-info-block type="error" [title]="errorTitle"/>-->
<!--            <div class="additional-info">-->
<!--                We regret to inform you that risk calculation is currently unavailable for-->
<!--                polygons, rectangles, and lines. Please try again by selecting points.-->
<!--                <br>-->
<!--                <br>-->
<!--                Our team is actively working on expanding the available options for future use.-->
<!--            </div>-->
<!--        </ng-container>-->
        <mat-accordion>
            <mat-expansion-panel hideToggle class="settings-panel"
                                 (opened)="isAdvancedOpened = true" (closed)="isAdvancedOpened = false">
                <mat-expansion-panel-header>
                    <div class="horizontal-separator"></div>
                    <p class="settings-title">{{ isAdvancedOpened ? 'Hide' : 'Show' }} Advanced Settings</p>
                    <div class="horizontal-separator"></div>
                </mat-expansion-panel-header>
                <p class="section-title">Advanced Settings</p>
                <!--                <p class="input-hint">Figma ipsum component variant main layer. Project main fill.</p>-->
                <div class="card-block">
                    <p class="section-title">Boundary Settings</p>
                    <div class="horizontal-separator"></div>
                    <p class="input-label">
                        Boundary Radius (m) <span class="icon-info" [matTooltip]="boundaryTooltip"
                                             matTooltipPosition="left"></span>
                    </p>
                    <mat-form-field appearance="outline">
                        <input matInput formControlName="boundary" placeholder="0" required [max]="2000" [min]="10" type="number">
                        <mat-error *ngIf="saveRiskReportGroup.get('boundary')?.hasError('required')">
                            Boundary Radius is required
                        </mat-error>
                        <mat-error *ngIf="saveRiskReportGroup.get('boundary')?.hasError('max')">
                            Boundary Radius should be 2000 or less
                        </mat-error>
                        <mat-error *ngIf="saveRiskReportGroup.get('boundary')?.hasError('min')">
                            Boundary should be more then 10
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="card-block">
                    <div class="card-header">
                        <p class="section-title">
                            Extreme Points Overlay
                            <span class="icon-info" [matTooltip]="extremePointsTooltip"  matTooltipPosition="left"></span>
                        </p>
                        <mat-slide-toggle
                                formControlName="enableExtremePoints"
                                labelPosition="before">
                        </mat-slide-toggle>
                    </div>
                </div>
                <div class="card-block" formGroupName="risk_threshold">
                    <div class="card-header">
                        <p class="section-title">Nearest Risk Overlay</p>
                        <mat-slide-toggle
                                formControlName="enableNearestRisk"
                                labelPosition="before">
                        </mat-slide-toggle>
                    </div>

                    <div class="horizontal-separator"></div>
                    <p class="input-label">
                        Nearest Risk Threshold<span class="icon-info" [matTooltip]="nearestTooltip"
                                                     matTooltipPosition="left"></span>
                    </p>
                    <p class="input-hint">Only areas within the selected threshold will be included in the report.</p>
                    <div class="slider-container">
                        <mat-slider
                                class="threshold-slider"
                                max="200"
                                min="0"
                                step="1"
                        >
                            <div class="threshold-slider-range">
                                <span>0</span>
                                <span>200</span>
                            </div>
                            <input matSliderStartThumb formControlName="nearest_risk_threshold_min"
                                   (valueChange)="validateNearestStart($event)"
                                   [value]="saveRiskReportGroup.value?.risk_threshold?.nearest_risk_threshold_min">
                            <input matSliderEndThumb formControlName="nearest_risk_threshold_max"
                                   [value]="saveRiskReportGroup.value?.risk_threshold?.nearest_risk_threshold_max">
                        </mat-slider>
                        <div class="input-container">
                            <mat-form-field appearance="outline" class="threshold-input">
                                <input matInput formControlName="nearest_risk_threshold_min" placeholder="0"
                                       [value]="saveRiskReportGroup.value?.risk_threshold?.nearest_risk_threshold_min">
                            </mat-form-field>
                            <span>-</span>
                            <mat-form-field appearance="outline" class="threshold-input">
                                <input matInput placeholder="200" formControlName="nearest_risk_threshold_max"
                                       [value]="saveRiskReportGroup.value?.risk_threshold?.nearest_risk_threshold_max">
                            </mat-form-field>
                        </div>
                    </div>
                </div>
<!--                <div class="card-block" formGroupName="zone_config">-->
<!--                    <div class="card-header">-->
<!--                        <p class="section-title">Zone Overlay-->
<!--                            <span class="icon-info" [matTooltip]="zoneTooltip"-->
<!--                                  matTooltipPosition="left"></span></p>-->
<!--                        <mat-slide-toggle-->
<!--                                formControlName="enableZone"-->
<!--                                labelPosition="before">-->
<!--                        </mat-slide-toggle>-->
<!--                    </div>-->
<!--                    <div class="horizontal-separator"></div>-->
<!--                    <p class="input-label">-->
<!--                        Zone Type-->
<!--                    </p>-->
<!--                    <mat-form-field appearance="outline">-->
<!--                        <mat-select formControlName="zone_type"-->
<!--                                    panelClass="search-panel">-->
<!--                            <mat-option value="behind">Behind</mat-option>-->
<!--                            <mat-option value="after">After</mat-option>-->
<!--                        </mat-select>-->
<!--                    </mat-form-field>-->
<!--                    <p class="input-label">-->
<!--                        Number of circles-->
<!--                    </p>-->
<!--                    <mat-form-field appearance="outline">-->
<!--                        <input type="number" matInput formControlName="point_num_circles" placeholder="0" required >-->
<!--                        <mat-error *ngIf="saveRiskReportGroup.get('zone_config').get('point_num_circles')?.hasError('required')">-->
<!--                            Number of circles is required-->
<!--                        </mat-error>-->
<!--                    </mat-form-field>-->
<!--                    <p class="input-label">-->
<!--                        Zone Width-->
<!--                    </p>-->
<!--                    <mat-form-field appearance="outline">-->
<!--                        <input type="number" matInput formControlName="zone_width" placeholder="0" required>-->
<!--                        <mat-error *ngIf="saveRiskReportGroup.get('zone_config').get('zone_width')?.hasError('required')">-->
<!--                            Zone Width is required-->
<!--                        </mat-error>-->
<!--                    </mat-form-field>-->
<!--                    <p class="input-label">-->
<!--                        Target Box Count-->
<!--                    </p>-->
<!--                    <mat-form-field appearance="outline">-->
<!--                        <input type="number" matInput formControlName="target_box_count" placeholder="0" required>-->
<!--                        <mat-error *ngIf="saveRiskReportGroup.get('zone_config').get('target_box_count')?.hasError('required')">-->
<!--                            Target Box Count is required-->
<!--                        </mat-error>-->
<!--                    </mat-form-field>-->
<!--                </div>-->
            </mat-expansion-panel>
        </mat-accordion>
    </div>

</div>
<div class="button-row ">
    <ng-container >
        <button mat-button mat-dialog-close>Cancel</button>
        <button mat-button cdkFocusInitial class="frmg-primary-button"
                [disabled]="saveRiskReportGroup.invalid || isLoading"
                (click)="save()">Generate Exposure Report
        </button>
    </ng-container>
<!--    <ng-container *ngIf="!hasPoints">-->
<!--        <button mat-button cdkFocusInitial class="frmg-primary-button"-->
<!--                mat-dialog-close>Ok-->
<!--        </button>-->
<!--    </ng-container>-->
</div>
