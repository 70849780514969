import { ChangeDetectorRef, Component, Input, OnInit, signal, ViewChild } from '@angular/core';
import { getRiskColors, truncate } from "../../../utils";
import { ReportService } from "../../../services/report.service";
import { AreaCollections, RiskReport, RiskReportAnalysis } from "../../../models/report.model";
import { ApexChart, ApexLegend, ApexNonAxisChartSeries, ApexTooltip, ChartComponent } from "ng-apexcharts";
import { ApexPlotOptions, ApexStroke } from "ng-apexcharts/lib/model/apex-types";
import { Router } from "@angular/router";
import { Sort } from "@angular/material/sort";
import { MapCommunicatorService } from "../../risk-map/map-communicator.service";
import { FormControl } from "@angular/forms";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { NotificationService } from "../../../services/notification.service";

export type ChartOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  labels: any;
  options: ApexPlotOptions;
  legend: ApexLegend,
  colors: string[],
  tooltip: ApexTooltip
  stroke: ApexStroke
};

@Component({
  selector: 'frmg-report',
  templateUrl: './areaReport.component.html',
  styleUrls: ['./areaReport.component.scss']
})
export class AreaReportComponent implements OnInit {

  @Input() id!: string;
  @Input() collectionId!: string;

  @ViewChild("chart") chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;

  public displayedColumns = ["name", "nearest_value_actual", "lowest_value_actual", "highest_value_actual", "percentile", "first_risk_score",  "avg_input_point_value", "risk_percentage"]
  public displayedColumnsDrag: string[] = [];
  public hiddenColumns: string[] = [];
  public reportDataFull: Array<AreaCollections> = [];
  public reportData: Array<AreaCollections> = [];
  public truncate = truncate;
  public report: RiskReport;
  public totalElements = 0;
  public legends: number[] = [0, 1, 2, 3, 4];
  public colors: string[] = getRiskColors();
  public avgScore: string = '0';
  public threshold: string = '0 - 200';
  public scoreTotal: number = 0;
  public sizeTotal: string = '0 km<sup>2</sup>';
  public areaSearchCtrl: FormControl = new FormControl<string>('');
  public reportNameCtrl: FormControl = new FormControl<string>('');
  public loadingData = signal(false);
  public columnMenu: boolean = false;
  public percentile: string;
  public isPolygon: boolean;

  constructor(private reportService: ReportService,
              private router: Router,
              private mapCommunicatorService: MapCommunicatorService,
              private notificationService: NotificationService,
              private cd: ChangeDetectorRef) {
    this.displayedColumnsDrag = this.displayedColumns.slice(1, -1);
  }

  ngOnInit() {
    this.getReport();

    this.chartOptions = {
      series: [0, 0, 0, 0, 0],
      labels: [
        "No Risk",
        "Low Risk",
        "Medium Risk",
        "High Risk",
        "Very High Risk",
      ],
      chart: {
        height: '110%',
        type: "donut",
        selection: {
          enabled: false
        },
        dropShadow: {
          enabled: false,
          top: 1,
          left: 3,
          blur: 3,
          opacity: 0.4,
          color: '#AEAEC0'
        }
      },
      options: {
        pie: {
          donut: {
            size: '25%',
            labels: {
              show: false,
              name: {
                show: false
              },
              value: {
                show: true
              }
            }
          },
          expandOnClick: false,
          dataLabels: {
            minAngleToShowLabel: 360
          },
        },

      },
      legend: {
        show: false
      },
      colors: this.colors,
      tooltip: {
        followCursor: false,
        cssClass: 'exposure-tooltip',
        y: {
          formatter: (value) => {
            return `<span style="color:#5E84F7">${(100 * value / this.totalElements).toFixed(0)}%</span> (${value})`;
          }
        },
        style: {
          fontFamily: "Montserrat"
        }
      },
      stroke: {
        width: 3
      }
    }

    this.areaSearchCtrl.valueChanges.subscribe(_ => {
      this.createTableData();
    })
  }

  private add(accumulator: number, a: number) {
    return accumulator + a;
  }

  public focusOn(element: RiskReportAnalysis, event: Event) {
    event.stopPropagation();
    this.mapCommunicatorService.mapCommunicator.next('focus_' + element.area.properties.id + '_true');
  }

  public highlightOnHover(element: RiskReportAnalysis) {
    this.mapCommunicatorService.mapCommunicator.next('hover_' + element.area.properties.id);
  }

  sortData(sort: Sort) {
    this.getReportInfo(sort)
  }
  
  private getReportInfo(sort?: Sort): void {
    this.loadingData.set(true)
    let config: any = {page: 1, page_size: 1000}
    if (sort && sort.direction !== '') {
      config.ordering = (sort.direction === 'asc' ? '' : '-') + sort.active;
    } else {
      config.ordering = 'name';
    }
    this.reportService.getReportAnalyseById(this.id, this.collectionId,  config).subscribe(result => {
      if (result.area.geometry.type === 'Polygon') this.isPolygon = true;
      this.report.total_length = result.total_length;
      this.reportService.breadCrumbs.next([result.name]);
      this.totalElements = result.area_details.length;
      this.scoreTotal = result.area_details.map((el) => el.risk_score).reduce(this.add, 0);
      this.avgScore = Math.round(this.scoreTotal / result.area_details.length) +'';
      const details = result.area_details.map((detail, index)=>{
        return {
          area: {
            geometry: detail.center_point,
            type: "Feature",
            properties: detail
          },
          id: detail.id,
          name: detail.address.name || `Point #${index+1}`,
          layer: detail.layer
        }
      })
      this.percentile = (details.map(data=> data.area.properties.percentile).reduce(this.add, 0) / details.length).toFixed(0)+'%';
      this.reportDataFull = [...[result], ...details];
      this.createTableData();
      const size = result.area.properties.area_in_sqm / 1000000;
      if (size > 1) {
        this.sizeTotal = size.toFixed(0) + ' km<sup>2</sup>';
      } else {
        this.sizeTotal = size.toFixed(2) + ' km<sup>2</sup>';
      }
      this.loadingData.set(false)
    })
  }

  private createTableData(): void {
    this.reportData = this.reportDataFull.filter((el, index) => {
      const searchStr = this.areaSearchCtrl.value.toLowerCase();
      return index !== 0 &&  (el.name?.toLowerCase().includes(searchStr) ||
        el.area.properties.address?.display_name?.toLowerCase().includes(searchStr) ||
        el.area.properties.address?.name?.toLowerCase().includes(searchStr));
    })
  }


  private getReport(): void {
    this.loadingData.set(true)
    this.reportService.getReport(this.id).subscribe(report => {
      this.report = report;
      this.reportNameCtrl.setValue(this.report.name);
      this.threshold = this.report.lowest_threshold + ' - ' + this.report.highest_threshold;
      this.getReportInfo();
    })
    this.reportService.getReportScore(this.id).subscribe(scores => {
      this.scoreTotal = Object.entries(scores).map((el) => +el[0] * el[1]).reduce(this.add, 0);
      this.chartOptions.series = Object.values(scores);
    })
  }
  public downloadArea(id: number, filename: string = 'report'): void {
    this.reportService.getAreaReportPdf(this.id, this.collectionId, filename).subscribe(data => {
      this.notificationService.updateDownload({
        fileName: filename + '.pdf',
        percentage: data.progress,
        total: data.total,
        loaded: data.loaded
      })
    })

  }

  public downloadAreaXLSX(id: number, filename: string = 'report'): void {
    this.reportService.getAreaReportXLSX(this.id, this.collectionId, filename).subscribe(data => {
      this.notificationService.updateDownload({
        fileName: filename + '.xlsx',
        percentage: data.progress,
        total: data.total,
        loaded: data.loaded
      })
    })
  }

  public openAnalysisModal(analysis: AreaCollections) {
    this.router.navigate([`report/${this.id}/collection/${this.collectionId}/details/${analysis.id}`])
  }

  public openAnalysisModalById(analysisId: string) {
    const analysis =this.reportData.find(analysis=>analysis.area.properties.id === analysisId)
    if (analysis && analysis.area.geometry.type == 'Point') this.openAnalysisModal(analysis)
  }


  public drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    this.displayedColumns = ['name', ...this.displayedColumnsDrag, 'action'];
  }

  public toggleColumn(index: number, column: string, show: boolean) {
    if (show) {
      this.hiddenColumns.push(column)
      this.displayedColumnsDrag.splice(index, 1)
    } else {
      this.displayedColumnsDrag.push(column)
      this.hiddenColumns.splice(index, 1)
    }
    this.displayedColumns = ['name', ...this.displayedColumnsDrag, 'action'];
    this.cd.detectChanges();
  }

  public toggleColumnMenu() {
    this.columnMenu = !this.columnMenu;
  }
  public segmentLength(element: RiskReportAnalysis): string {
    const prop = element.area.properties;
    const from = ((prop.from_distance+2000)/1000).toFixed(0);
    const to = ((prop.to_distance+2000)/1000).toFixed(0);
    return `Segment from ${from}km to ${to}km`;
  }
}
